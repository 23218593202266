import http from "@/common/js/http";

/**
 * 获取提现规则
 * @param data
 */
export const getDriverWithdrawRuleApi = async (data) => {
    return await http(`/driverAccount/getDriverWithdrawRule `, data, 'POST');
};


/**
 * 更新提现规则
 * @param data
 */
export const updateDriverWithdrawRuleApi = async (data) => {
    return await http(`/driverAccount/updateDriverWithdrawRule`, data, 'POST');
};


/**
 * 新增提现规则
 * @param data
 */
export const addDriverWithdrawRuleApi = async (data) => {
    return await http(`/driverAccount/addDriverWithdrawRule`, data, 'POST');
};

<script>
import comListSearch from "@/www/components/comListSearch/index.vue";
import selectCity from "@/components/selectCity/index.vue";
import supplierSelect from "@/www/components/supplier-select/index.vue";
import operatorSelect from "@/www/components/operator-select/index.vue";
import driverBalanceTop from "@/www/pages/driverWithdrawal/driverBalance/componetns/driver-balance-top.vue";
import {getBmsDriverAccountListApi, updateDriverAccountApi} from "@/www/urls/wallet";
import {combinationForm} from "@/common/js/common";

const initForm = () => ({
    pageIndex: 1,
    pageSize: 10
})
export default {
    name: "driverBalance",
    components: {
        comListSearch, selectCity,
        supplierSelect,
        operatorSelect,
        driverBalanceTop
    },
    data() {
        return {
            loading: false,
            total: 0,
            list: [],
            summary: {},
            withdraw: {},
            form: initForm()
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        async getList() {
            try {
                this.loading = true
                const ret = await getBmsDriverAccountListApi({
                    ...combinationForm(this.form),
                    driverStatus: this.form.driverStatus === -1 ? undefined : this.form.driverStatus,
                    driverAccountStatus: this.form.driverAccountStatus === -1 ? undefined : this.form.driverAccountStatus,
                })
                if (ret.success && ret.data) {
                    this.total = ret.data.pageList.total
                    this.list = ret.data.pageList.data.map((item) => ({
                        ...item,
                        driverAccountOpend: item.driverAccountStatus === '0'
                    }))
                    this.summary = ret.data.price
                    this.withdraw = {
                        withdrawCount: ret.data.withdrawCount,
                        withdrawDay: ret.data.withdrawDay
                    }
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        reset() {
            this.form = initForm()
            this.getList()
            this.$refs['select-city'].clear()
        },
        handleDetail(info) {
            this.$router.push('/driverBalance/detail?record=' + JSON.stringify(info))
        },
        async handleDriverAccountStatusChange(record) {
            try {
                this.loadingShow()
                await updateDriverAccountApi({
                    id: record.driverAccountId,
                    status: record.driverAccountStatus === '0' ? 1 : 0,
                    driverId: record.driverId
                })
                this.getList()
            } finally {
                this.loadingHide()
            }
        }
    }
}
</script>

<template>
    <div>
        <el-main class="com-eTrip-section" :class="{'section-has-footer':list.length>0}" v-loading="loading">
            <div class="eTrip-section-cont driver-balance-list">
                <driver-balance-top ref="driver-balance-top" :withdraw="withdraw"
                                    :summary="summary"></driver-balance-top>
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form inline size="medium">
                            <el-form-item>
                                <el-input placeholder="司机姓名" clearable v-model="form.driverName"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="司机手机" type="tel" :max-length="11" clearable
                                          v-model="form.driverMobile"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <select-city ref="select-city" placeholder="服务城市" :is-border="false" size="medium"
                                             @update:city="form.cityId=$event.shortCode"></select-city>
                            </el-form-item>
                            <el-form-item>
                                <operator-select placeholder="所属运营商" v-model="form.operatorId"></operator-select>
                            </el-form-item>
                            <el-form-item>
                                <supplier-select placeholder="所属供应商" v-model="form.supplierId"></supplier-select>
                            </el-form-item>
                            <el-form-item>
                                <el-select placeholder="司机状态" v-model="form.driverStatus">
                                    <el-option label="全部" :value="-1">全部</el-option>
                                    <el-option label="可用" :value="1">可用</el-option>
                                    <el-option label="不可用" :value="0">不可用</el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <el-select placeholder="司机自主提现" v-model="form.driverAccountStatus">
                                    <el-option label="全部" :value="-1">全部</el-option>
                                    <el-option label="开启" :value="0">开启</el-option>
                                    <el-option label="关闭" :value="1">关闭</el-option>
                                </el-select>
                            </el-form-item>
                            <!--                            <el-form-item>-->
                            <!--                                <el-select placeholder="车租代缴">-->
                            <!--                                    <el-option label="-1" :value="-1">全部</el-option>-->
                            <!--                                    <el-option label="1" value="0">开启</el-option>-->
                            <!--                                    <el-option label="0" value="1">关闭</el-option>-->
                            <!--                                </el-select>-->
                            <!--                            </el-form-item>-->
                            <!--                            <el-form-item>-->
                            <!--                                <el-select placeholder="结算方式">-->
                            <!--                                    <el-option label="-1" :value="-1">全部</el-option>-->
                            <!--                                    <el-option label="1" :value="1">自提</el-option>-->
                            <!--                                    <el-option label="0" :value="0">现结</el-option>-->
                            <!--                                </el-select>-->
                            <!--                            </el-form-item>-->
                        </el-form>
                    </div>
                    <div slot="list-search-after">
                        <el-button @click="reset()">重置</el-button>
                        <el-button type="primary" @click="getList(1)">筛选</el-button>
                    </div>
                </com-list-search>
                <el-table :data="list">
                    <el-table-column label="司机姓名手机" width="160" prop="driverMobile"
                                     align="left">
                        <template slot-scope="scope">
                            {{ scope.row.driverName || '' }}{{ scope.row.driverMobile }}
                        </template>
                    </el-table-column>
                    <el-table-column label="提现账户" align="center" width="160">
                        <template slot-scope="scope">
                            {{ scope.row.realName || '' }}{{ scope.row.aliPayAccount }}
                        </template>
                    </el-table-column>
                    <el-table-column label="服务城市" prop="cityName" align="center"></el-table-column>
                    <el-table-column label="所属运营商" width="120" prop="operatorName"
                                     align="center"></el-table-column>
                    <el-table-column label="所属供应商" width="120" prop="supplierName"
                                     align="center"></el-table-column>
                    <el-table-column label="钱包余额" prop="accountBalance" align="center"
                                     width="100"></el-table-column>
                    <el-table-column label="可提现余额" width="100" prop="totalPrice" align="center"></el-table-column>
                    <el-table-column label="冻结余额" prop="freezePrice" align="center" width="80"></el-table-column>
                    <!--                    <el-table-column label="车租代缴"></el-table-column>-->
                    <el-table-column label="司机状态" align="center" width="80">
                        <template slot-scope="scope">
                            {{ scope.row.driverStatus === 1 ? '可用' : '不可用' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="司机提现" align="center" width="120">
                        <template slot-scope="scope">
                            <span class="mr10"> {{ scope.row.driverAccountOpend ? '开启' : '关闭' }}</span>
                            <el-switch v-model="scope.row.driverAccountOpend"
                                       @change="handleDriverAccountStatusChange(scope.row)"></el-switch>
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column label="车租代缴"></el-table-column>-->
                    <!--                    <el-table-column label="费用结算"></el-table-column>-->
                    <el-table-column label="操作项">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleDetail(scope.row)">查看明细</el-button>
                            <!--                            <el-button type="text" size="mini">调账</el-button>-->
                            <!--                            <el-button type="text" size="mini">缴租</el-button>-->
                            <!--                            <el-button type="text" size="mini">日志</el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px" v-if="list.length>0">
            <div class="eTrip-footer-cont">
                <el-pagination
                    @current-change="getList"
                    :current-page.sync="form.pageIndex"
                    :page-size="form.pageSize"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </el-footer>
    </div>
</template>

<style scoped lang="scss">
.eTrip-footer-cont {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
}
</style>

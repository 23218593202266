<script>
import moment from "moment";
import {getDriverWithdrawRuleApi} from "@/www/urls/driverWithdrawal";
import countTo from 'vue-count-to';

export default {
    name: "driver-balance-top",
    components: {
        countTo
    },
    props: {
        withdraw: {
            type: Object,
            default: () => ({})
        },
        summary: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            loading: false,
            rule: {
                withdrawWeekDay: []
            }
        }
    },
    computed: {
        isToady() {
            return moment(this.withdraw.withdrawDay).isSame(moment(), 'day')
        },
        nextDay() {
            const now = moment()
            const withdrawWeekDay = this.rule.withdrawWeekDay
            withdrawWeekDay.sort()
            let nextDay = now.clone().startOf('month').add(withdrawWeekDay.find((day) => now.clone().startOf('month').add(day - 1, 'day').isSameOrAfter(now.clone())) - 1, 'd').valueOf()
            if (!nextDay)
                nextDay = now.clone().startOf('month').add(1, 'month').add(withdrawWeekDay[0] - 1, 'day').valueOf()
            return nextDay
        },
        list() {
            return [
                {
                    title: '钱包余额',
                    value: Number(this.summary.totalAccountBalance) + Number(this.summary.totalFreezePrice),
                    bg: '#DFEFFF'
                },
                {
                    title: '可提现余额',
                    value: Number(this.summary.totalAccountBalance),
                    bg: '#EAFAD8'
                },
                {
                    title: '冻结余额',
                    value: Number(this.summary.totalFreezePrice),
                    bg: '#FFEBEB'
                }
            ]
        }
    },
    activated() {
        this.getRule();
    },
    methods: {
        async getRule() {
            try {
                this.loading = true
                const ret = await getDriverWithdrawRuleApi()
                console.log(ret);
                if (ret.success && ret.data) {
                    this.rule = {
                        ...ret.data,
                        withdrawWeekDay: JSON.parse(ret.data.withdrawWeekDay)
                    }
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        handleCheckDetail() {
            this.$router.push('/driverWithdrawal/record?date=' + this.withdraw.withdrawDay)
        }
    },
}
</script>

<template>
    <ul class="driver-balance-top" v-loading="loading">
        <li style="background-color: #F0F2F5;min-width: 290px">
            <div>
                <p>
                    {{ isToady ? '今天 提现日' : '上一提现日' }}
                    <span class="ml10 font_s12">下一提现日 {{ nextDay | dateCus('M月d日') }}</span>
                </p>
                <p class="mt5 font_s16 font-w6">{{ this.withdraw.withdrawDay | dateCus('M月d日') }}
                    收到{{ withdraw.withdrawCount || 0 }}笔提现申请</p>
            </div>
            <el-button type="primary" plain size="small" @click="handleCheckDetail">查看</el-button>
        </li>
        <li v-for="vm in list" :key="vm.title" :style="{backgroundColor:vm.bg}">
            <p>{{ vm.title }}(合计)</p>
            <p>
                <countTo style="font-size: 20px;font-weight: 500" :startVal='0' :endVal='vm.value||0' :duration='1000'
                         separator="" :decimals="2"></countTo>
                <span>元</span>
            </p>
        </li>
    </ul>
</template>

<style scoped lang="scss">
.driver-balance-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 0 10px 20px;

    li {
        padding: 0 20px;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 3px;
        flex: 1;


        &:not(:last-child) {
            margin-right: 11px;
        }
    }
}
</style>
